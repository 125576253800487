.horizontal-scroll {
  position: relative;
  white-space: nowrap;
  padding: 0 30px;

  .horizontal-scroll-inner {
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
  }
}

.vertical-scroll {
  position: relative;
  overflow-y: hidden;
  height: inherit;

  .top-shadow {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    z-index: 1001;
    box-shadow: 0px 1px 45px 45px rgba(44, 14, 19, 1);
  }

  .bottom-shadow {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    z-index: 1001;
    box-shadow: 0px -1px 45px 45px rgba(44, 14, 19, 1);
  }

  .vertical-scroll-inner {
    position: relative;
    height: inherit;
    overflow-y: hidden;
    overflow-x: hidden;
  }
}

.scroll-button {
  position: absolute;
  background-color: transparent;
  border: none;
  color: white;
  text-align: center;
  vertical-align: middle;
  opacity: 0.85;
  z-index: 1001;

  &:focus {
    outline: 1px solid rgba(255, 255, 255, 0.1);
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  &.left-scroll-button {
    top: 0;
    bottom: 0;
    left: 0;
    width: 25px;
  }

  &.right-scroll-button {
    top: 0;
    bottom: 0;
    right: 0;
    width: 25px;
  }

  &.down-scroll-button {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25px;
  }

  &.up-scroll-button {
    top: 0;
    left: 0;
    width: 100%;
    height: 25px;
  }
}
