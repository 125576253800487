.help-with-measurement {
  .app-buy-mold-content {
    font-size: 16px;
    color: $color;

    h3 {
      margin-top: 30px;
    }

    h4 {
      margin-top: 10px;
      margin-bottom: 40px;
    }

    ul > li {
      margin-top: 15px;
      margin-bottom: 35px;
    }
  }

  .col-md-4:first-child {
    text-align: center;
    img {
      width: 80%;
    }
  }

  .col-md-4 + .col-md-4 + .col-md-4 {
    padding: 0 30px;
    border-left: 1px solid lighten($color, 30%);
    p {
      margin-bottom: 30px;
    }

    .row > .col-md-12 > * {
      vertical-align: top;
    }

    .mc-icon.mc-icon-inverse * {
      fill: red;
    }

    img {
      width: 40%;
    }
  }
}
