.login-page-step {
  width: 100%;
  a {
    color: #fff;
  }

  .extra-link {
    font-size: 2vh;
    font-weight: 600;
  }

  .conta-gratuita {
    color: #fff;

    @include media-breakpoint-up(md) {
      font-size: 2vw;
      font-weight: 400;
      margin-top: 15vh;
    }
  }

  .content,
  form {
    @include media-breakpoint-up(md) {
      position: absolute;
      left: 40%;
      top: 26%;
      width: 50%;
      height: 60%;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    > * {
      margin-top: 5vh;
    }

    .form-control {
      text-align: center;
      background-color: transparent !important;
      border: 0;
      border-bottom: 1px solid #fff;
      box-shadow: unset;
      color: #fff !important;

      @include media-breakpoint-up(md) {
        height: 5vh;
        font-size: 2.7vh;
        width: 80%;
      }

      &:first {
        margin-top: 10vh;
      }
    }
  }

  .input-with-addon {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 80%;
    }
    margin-top: 5vh;
    position: relative;

    .form-control {
      width: 100%;
      margin-top: 0;
    }

    button {
      background-color: transparent;
      border: 0;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  button.btn-custom,
  button[type='submit'] {
    background-color: rgb(0, 196, 201);
    width: 60%;
    height: 8vh;
    font-size: 3.5vh;
    font-weight: bold;
    color: rgb(255, 255, 255);
    outline: 0;
    margin-bottom: 10vh;
    border: 0px;
  }

  .error-message {
    position: absolute;
  }
}

.request-password-recover {
  p {
    font-size: 3vh;
  }

  h3 {
    font-size: 3vh;
    font-weight: 700;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }
}
