.model-types {
  display: block;
  position: relative;
  padding: 0 15px 30px 30px;

  .grid-model-types {
    position: relative;

    .model-type {
      padding-left: 0;
    }

    .thumbnail {
      background-color: $default-light-transparency;
      border-color: transparent;
      cursor: pointer;
      display: block;
      padding: 4px;
      margin-bottom: 20px;
      line-height: 1.42857;
      border-radius: 0;
      transition: border 0.2s ease-in-out;

      &:hover {
        background-color: rgba($default-light-transparency, 0.5);
      }

      .caption {
        text-align: center;
        text-transform: uppercase;
        font-size: 0.7em;
        padding: 10px 0 0 0;
        color: #ddd;
      }

      app-model-front {
        display: block;
        padding: 10px 10px 0 10px;
      }
    }
  }
}
