.buy-mold-selected-size {
  display: block;
  position: absolute;
  left: -25px;
  margin-top: -10px;

  svg {
    polygon {
      fill: #008a8d;
    }
    path {
      fill: #013c4d;
    }

    text {
      font-size: 14px;
      fill: #fff;
      font-weight: 500;
    }
  }
}
