$icon-color: rgba(#fff, 0.8);
$icon-hover-color: rgba(#fff, 1);
$icon-color-disabled: rgba(#fff, 0.5);
$icon-inverse-color: rgba(#000, 0.8);
$icon-inverse-hover-color: rgba(#000, 1);
$icon-inverse-color-disabled: rgba(#000, 0.5);

.mc-icon {
  display: inline-block;

  .mc-icon-svg {
    vertical-align: top;
    width: 16px;
    height: 16px;
  }

  * {
    fill: $icon-color;
  }

  &.mc-icon-inverse {
    * {
      fill: $icon-inverse-color;
    }
  }

  &.mc-icon-lg .mc-icon-svg {
    width: 25px;
    height: 25px;
  }

  &.mc-icon-xs .mc-icon-svg {
    width: 9px;
    height: 9px;
  }
}

a:hover {
  .mc-icon {
    * {
      fill: $icon-hover-color;
    }

    &.mc-icon-inverse {
      * {
        fill: $icon-inverse-hover-color;
      }
    }
  }
}

.disabled {
  a,
  a:hover {
    .mc-icon {
      * {
        fill: $icon-color-disabled;
      }

      &.mc-icon-inverse {
        * {
          fill: $icon-inverse-color-disabled;
        }
      }
    }
  }
}
