.whatsapp {
  position: absolute;
  right: 15px;
  bottom: 15px;
  z-index: 1000;
  background-color: #25d366;
  border-radius: 100px;
  padding: 15px;
  border: none;

  &:hover {
    opacity: 0.8;
  }

  img {
    width: 40px;
  }

  @include media-breakpoint-down(md) {
    padding: 10px;
    right: 10px;
    bottom: 10px;

    img {
      width: 25px;
    }
  }
}