.selected-fabric {
  .image {
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.4);
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    .image-item {
      display: block;
      position: absolute;
      width: 100%;
      top: 0;
      border: none;
      visibility: visible;
    }

    .image-cue {
      visibility: hidden;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      color: #ddd;
      padding: 40px;
      background-color: rgba($default-dark-transparency, 0.3);

      div {
        position: relative;
        height: 100%;
        width: 100%;
        text-align: center;
        display: table;

        span {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }

    .image-remove {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }

      img {
        width: 15px;

        &,
        * {
          fill: #fff;
        }
      }
    }

    &:hover {
      cursor: pointer;

      .image-cue {
        visibility: visible;
      }
    }

    &.image-empty {
      box-shadow: none;

      .image-item {
        visibility: hidden;
      }

      .image-remove {
        display: none;
      }

      .image-cue {
        visibility: visible;

        div {
          background-color: transparent;
        }

        &:hover {
          background-color: rgba($default-dark-transparency, 0.4);
        }
      }
    }
  }
}
