.preview-selected-model {
  display: flex;
  flex: 1;
  background-image: url('/assets/bg/violate.jpg');
  background-position: center center;
  background-color: transparent;

  > div {
    background-color: rgba(#e6beb6, 0.6);
    padding: 40px 60px 20px 60px;
    box-shadow: inset 0px 0px 30px 5px $shadow-color;
  }
}
