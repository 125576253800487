$padding-top: 50px;

.empty-doll,
.render-model {
  display: block;
  height: 100%;
  width: 100%;

  .doll-stroke {
    stroke: #d17869 !important;
    stroke-width: 0.1pt;
    fill: none;
  }

  .no-fill {
    fill: none;
    visibility: hidden;
  }

  #unfolding {
    width: 100%;
  }

  [id^='Frente'] {
    [id^='Boneca'] {
      [id^='Traço'] {
        stroke-width: 0.06;
        stroke: #373435 !important;
      }
    }
  }

  [id^='Costas'] {
    [id^='Boneca'] {
      [id^='Traço'] {
        stroke-width: 0.05;
        stroke: #373435 !important;
      }
    }
  }

  [id^='Boneca'] {
    [id^='Traço'] {
      stroke: black;
    }

    [id^='Cor'] {
      fill: rgba(#e2c7ab, 0.4) !important;
    }
  }

  rect[id^='Retângulo'] {
    stroke: transparent !important;
  }
}

.show-model {
  display: block;
  height: 100%;
  width: 100%;
  padding: $padding-top $selected-model-toolbar-width 30px $selected-model-toolbar-width;
  position: relative;

  .model {
    height: 100%;
    width: 100%;
  }

  .model-remove {
    position: absolute;
    top: 5px;
    right: $selected-model-toolbar-width * 2;
    width: 15px;
    height: 15px;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }

    img {
      width: 100%;
    }
  }
}

.empty-doll {
  display: block;
  height: 100%;
  width: 100%;

  svg {
    .str0 {
      stroke: #373435;
      stroke-width: 0.0349368;
      stroke-linecap: square;
      stroke-linejoin: bevel;
    }
    .str1 {
      stroke: #cd9f6e;
      stroke-width: 0.0916976;
      stroke-linecap: square;
      stroke-linejoin: bevel;
    }
    .str2 {
      stroke: #cd9f6e;
      stroke-width: 0.114622;
      stroke-linecap: square;
      stroke-linejoin: bevel;
    }
    .fil0 {
      fill: none;
    }
    .fil1 {
      fill: #ede0d7;
    }
  }
}
