.modelo-svg {
  path[id^='Boneca'] {
    stroke: #f1ece6 !important;
  }

  [id^='Boneca'] {
    [id^='Traço'] {
      stroke: #373435 !important;
    }

    [id^='Cor'] {
      fill: rgba(#e2c7ab, 0.4) !important;
    }
  }

  rect[id^='Retângulo'] {
    stroke: transparent !important;
  }

  [id^='Frente'] {
    [id^='Boneca'] {
      [id^='Traço'] {
        stroke-width: 0.06;
      }
    }
  }

  [id^='Costas'] {
    [id^='Boneca'] {
      [id^='Traço'] {
        stroke-width: 0.05;
      }
    }
  }

  .str0 {
    stroke: #373435;
    stroke-width: 0.0349368;
    stroke-linecap: square;
    stroke-linejoin: bevel;
  }
  .str1 {
    stroke: #cd9f6e;
    stroke-width: 0.0916976;
    stroke-linecap: square;
    stroke-linejoin: bevel;
  }
  .str2 {
    stroke: #cd9f6e;
    stroke-width: 0.114622;
    stroke-linecap: square;
    stroke-linejoin: bevel;
  }
  .fil0 {
    fill: none;
  }
  .fil1 {
    fill: #ede0d7;
  }
}
