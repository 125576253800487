@import 'StepAcceptContract';
@import 'StepSelectAddress';
@import 'StepSummary';

#icon-modacad-cart {
  position: absolute;
  width: 9%;
  height: 7%;
  top: 5%;
  left: 60%;
}

#logo-modacad-compra {
  width: 75%;
}

.arrow-back {
  position: absolute;
  top: 5%;
  left: 30%;
  cursor: pointer;
}
