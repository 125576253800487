$digital-banner-color: #fd575b;

.digital-sales-banner {
  position: fixed;
  top: 0;
  left: 20%;
  right: 20%;
  z-index: 9999;
  text-align: center;
  color: $digital-banner-color;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2rem;

  img {
    height: 60px;
    margin: 0 10px;
    vertical-align: top;
  }

  span {
    margin-top: 8px;
    display: inline-block;
  }

  @include media-breakpoint-down(md) {
    right: 60%;

    span {
      display: none;
    }

    img {
      height: 70px;
    }
  }
}