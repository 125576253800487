$off-color: #cccccc;
$on-color: #05bcc1;

.onoffswitch {
  position: relative;
  width: 45px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    // border: 2px solid #999999;
    border-radius: 50px;
  }
  .onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  .onoffswitch-inner:before,
  .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 18px;
    padding: 0;
    line-height: 18px;
    font-size: 18px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    box-sizing: border-box;
  }
  .onoffswitch-inner:before {
    content: '';
    padding-left: 12px;
    background-color: $on-color;
    color: #ffffff;
  }
  .onoffswitch-inner:after {
    content: '';
    padding-right: 12px;
    background-color: $off-color;
    color: #999999;
    text-align: right;
  }
  .onoffswitch-switch {
    display: block;
    width: 22px;
    margin: -2px;
    background: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 25px;
    border: 2px solid $off-color;
    border-radius: 50px;
    transition: all 0.3s ease-in 0s;
  }

  &.onoffswitch-checked {
    .onoffswitch-switch {
      border-color: $on-color;
    }
    .onoffswitch-inner {
      margin-left: 0;
    }
    .onoffswitch-switch {
      right: 0px;
    }
  }
}
