@import 'DigitalSalesBanner';
@import 'FormCustomSwitch';
@import 'not-found-page';
@import 'scroll/scroll';
@import 'sidebar';
@import 'SVGModelo';
@import 'toggle-switch';
@import 'WhatsAppBtn';

.btn-transparent {
  background-color: transparent;
  border: none;
}