.selected-model-variations {
  position: absolute;
  top: $selected-model-variations + 55px;
  bottom: 0;
  width: 100%;
  overflow-y: auto;

  .variations {
    height: 100%;
  }

  .variations-inner {
    background-color: $default-dark-transparency;
    border: 1px solid $default-dark-border-color;
    border-bottom-color: transparent;
    border-top-color: transparent;
    padding: 5px 10px;

    > .btn {
      text-transform: uppercase;
    }
  }
}

.selected-model-variation {
  text-align: center;
  padding: 15px 20px;

  .title {
    color: #fff;
    font-size: 1.2em;
    margin-bottom: 5px;
  }
}
