$default-dark-transparency: rgba(0, 0, 0, 0.4);

.account-invite-desktop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;

  background-color: $default-dark-transparency;
  width: 100%;
  height: 100%;

  .left-panel {
    position: relative;
  }

  #curve {
    height: 100%;
  }

  #logo-modacad {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    padding: 0 50px;
  }

  .container-convida {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #logo-modacad-2 {
    width: 9%;
    height: 7%;
    top: 20%;
  }

  .esta-gostando {
    color: #fff;
    font-size: 4vh;
    font-weight: 500;
  }

  .crie-conta {
    color: #fff;
    font-size: 4vh;
  }

  .conta-ilimitada {
    z-index: 3;
    color: #fff;
    font-size: 3.5vh;
    font-weight: 500;
    margin-top: 10%;
  }

  .button-criar {
    background-color: rgb(0, 196, 201);
    font-size: 3.5vh;
    font-weight: bold;
    color: rgb(255, 255, 255);
    z-index: 3;
    width: 40%;
    height: 8vh;
    border: 0px;
    text-align: center;
    margin-top: 5%;
  }

}

.account-invite-mobile {
  position: fixed;
  top: 0;
  left: 0;

  background-color: $default-dark-transparency;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  .vertical-curve {
    background-color: #fff;
    height: 35vh;
    text-align: center;

    img {
      margin-top: 8vh;
      margin-bottom: 8vh;
      width: 80%;
    }

    p {
      color: #b9162a;
      font-size: 20px;
    }
  }

  .container-convida {
    height: 65vh;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20vh;
    padding-top: 5vh;

    p {
      color: #fff;
      font-size: 20px;
    }

    button {
      background-color: #00c4c9;
      font-size: 20px;
      font-weight: bold;
      color: rgb(255, 255, 255);
      width: 80%;
      border: 0px;
      text-align: center;
      margin-top: 5%;
    }
  }
}