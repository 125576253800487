input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  border-bottom: 1px solid #fff;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: unset;
  transition: background-color 5000s ease-in-out 0s;
}
