.main-navbar {
  background-color: rgba($default-dark-transparency, 0.6);
  border-color: rgba($default-dark-transparency, 0.1);
  box-shadow: 0px 2px 5px 0px rgba($default-dark-transparency, 0.4);

  .navbar-brand {
    padding: 0;
    img {
      height: 26px;
    }
  }
  ul.navbar-nav {
    svg {
      max-height: 25px;
    }
    li > a {
      padding: 0;
      > .mc-icon > .mc-icon-svg {
        height: 30px;
        width: 45px;
      }
    }
  }
}
