@import 'HelpWithMeasurement';

.buy-mold-measures {
  font-size: $base-font;
  color: $color;

  .col-form-label {
    text-align: right;
    font-weight: 700;
  }

  .app-buy-mold-content {
    h4 {
      color: darken($color, 10%);
    }

    p {
      font-size: $base-font * 0.8;
      padding: 5px 30px;
    }

    .calculando,
    .calculado,
    .erro-calculo,
    .aguardando {
      color: $color;
      height: 75px;
      font-size: 16px;
    }

    .calculado {
      span {
        display: inline-block;
        vertical-align: middle;
      }

      .mc-icon .mc-icon-svg {
        vertical-align: middle;
      }
    }
  }

  hr + .text-center {
    a {
      background-color: rgba($mc-primary, 0.2);
      border: 1px solid $mc-primary;
      padding: 10px 20px;

      &:hover {
        background-color: rgba($mc-primary, 0.3);
      }
    }

    .mc-icon {
      padding-right: 10px;

      .mc-icon-svg {
        vertical-align: middle;
        margin-left: -40px;
        height: 22px;
        width: 22px;

        * {
          fill: darken($mc-primary, 5%) !important;
        }
      }
    }
  }
}
