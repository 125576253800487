@import 'ModelTypes';

.models-catalog {
  height: 100%;
  overflow: hidden;
  background-color: $default-dark-transparency;
  position: relative;
  border: 1px solid $default-dark-border-color;
  border-top: none;
  box-shadow: 0px 1px 1px 0px $default-dark-transparency;

  .breadcrumb {
    padding: 10px 30px;
  }

  .model-navigation {
    position: absolute;
    top: 47px;
    bottom: 0;
    width: 100%;
    z-index: 0;

    > div.vertical-scroll {
      height: 100%;
      position: relative;
    }
  }
}

.model-types-by-fabric-subgroup {
  .empty {
    padding: 15px 30px;
    color: #fff;
  }
}

.model-thumbnail {
  display: inline-block;
}

.models-by-fabric-group,
.models-by-fabric-subgroup {
  color: white;

  .loading {
    margin: 5px 30px;
  }

  .title {
    margin-top: 10px;
    margin-bottom: 5px;
    text-transform: uppercase;
    padding: 0 30px;
  }

  hr {
    margin: 0 30px;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .thumbnail {
    display: inline-block;
    position: relative;

    vertical-align: text-top;
    margin-right: 15px;
    margin-bottom: 10px;
    width: 80px;

    background-color: $default-light-transparency;
    border-color: transparent;
    box-shadow: 0px 0px 10px 1px $default-dark-transparency;

    .caption {
      padding: 0;
      color: #ddd;
    }

    .model {
      position: relative;
      width: 100%;
      padding: 10px;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba($default-light-transparency, 0.5);
    }
  }
}
