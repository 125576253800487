$selected-model-toolbar-width: 60px;
$selected-model-variations: 180px;

@import 'ActionsToolbar';
@import 'MoldToolbar';
@import 'ShowModel';

.catwalk {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 10px 5px;

  .catwalk-area {
    margin-right: $selected-model-variations - $selected-model-toolbar-width;
    position: relative;
    height: 100%;
  }
}
