$sidebar-zindex: $zindex-sticky;
$sidebar-toggler-color: $navbar-dark-color;
$sidebar-toggler-focus-color: $navbar-dark-active-color;
$sidebar-toggler-hover-color: $navbar-dark-hover-color;

.sidebar-opener {
  position: absolute;
  top: 10px;
  left: 5px;

  @include media-breakpoint-up(sm) {
    position: inherit;
    top: unset;
    left: unset;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  background-color: rgba($black, 0.2);
  z-index: $sidebar-zindex + 20;

  &.open {
    -webkit-transform: translateX(0);
    transform: translateX(0); /* Account for horizontal padding on navbar */
  }

  .overlay {
    position: relative;
    height: 100%;
    overflow-y: auto;
    margin-right: 3rem;
    background-color: var(--gray-dark);

    @include media-breakpoint-up(sm) {
      margin-right: 50%;
    }

    @include media-breakpoint-up(md) {
      margin-right: 60%;
    }

    @include media-breakpoint-up(lg) {
      margin-right: 70%;
    }

    @include media-breakpoint-up(xl) {
      margin-right: 75%;
    }

    .versao {
      width: 100%;
      position: absolute;
      bottom: 10px;

      p {
        color: white;
        font-size: 0.8rem;
      }
    }
  }

  .navbar {
    .logo {
      padding: 1rem;
      border-bottom: 1px solid rgba($gray-100, 0.5);
      img {
        width: 100%;
      }
    }

    .navbar-nav {
      width: 100%;

      .nav-item {
        padding: 0.5rem;
        border-bottom: 1px solid rgba($gray-100, 0.3);

        button {
          @extend a;
          border: none;
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}
