.confirm-order {
  position: absolute;
  left: 42%;
  top: 13%;
  width: 45%;
  height: 80%;
  min-height: 200px;
  padding: 0 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  color: #fff;
  ::-webkit-scrollbar {
    width: 0px;
  }
  .text-confirm-order {
    font-weight: bold;
    font-size: 2.5vh;
  }

  .itens-cart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 5vh;
    width: 50%;
    min-height: 50px;
    height: 45%;
    max-height: 45%;
    padding: 5px 15px 5px 15px;
    background-color: rgba(0, 0, 0, 0.4);
    overflow: auto;

    p:first-child {
      font-size: 1.8vh;
    }
    p {
      margin-top: 0%;
      margin-bottom: 0%;
      font-size: 1.65vh;
    }
    .sizes-item {
      font-size: 1.4vh;
    }
    .item {
      padding: 5px 0px 0px 0px;
    }
    #hr {
      height: 0.1vh;
      margin-top: 5px;
      width: 100%;
      background-color: #fff;
    }
  }

  svg {
    margin-top: 10px;
  }

  p {
    font-size: 1.8vh;
    margin-top: 1vh;
    a {
      cursor: pointer;
      color: #fff;
    }
    margin-bottom: 20px;
  }

  .cupom {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    .form-control {
      color: #ffffff;
      height: 4vh;
      font-size: 1.2vw;
      font-weight: lighter;
      background-color: rgba(0, 0, 0, 0.4);
      border: 0px;
      padding: 0 10 0 0;
      box-shadow: unset;
      text-align: left;
    }
    .btn {
      background-color: #4dc492;
      font-size: 2vh;
      line-height: 2vh;
      height: 4vh;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
      outline: none;
      color: #fff !important;
    }
  }

  .values {
    width: 50%;
    p {
      font-size: 1.8vh;
      margin-bottom: 0px;
    }
    margin-bottom: 0px;
  }

  .btn.btn-primary {
    background-color: rgb(0, 196, 201);
    width: 45%;
    height: 8vh;
    font-size: 3vh;
    font-weight: bold;
    color: rgb(255, 255, 255);
    margin-top: 3vh;
    border: 0px;
    outline: none;
  }
}
