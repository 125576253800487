.my-orders {
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
  @extend .full-height;

  > header {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 15px 30px;
    color: white;

    h3 {
      margin-top: 10px;
    }
  }

  > section {
    flex: 1;
    background-color: rgba(255, 255, 255, 0.16);
    display: flex;
    overflow: auto;
    flex-direction: column;

    .orders {
      border-top: 1px dashed rgba(255, 255, 255, 0.3);
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-bottom: 0;

      .item {
        color: #fff;
        border: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: transparent;

        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }

    .no-orders {
      padding: 2em;
    }
  }
}
