.pagina-nao-encontrada {
  display: flex;
  height: 100vh;
  justify-content: space-around;
  align-items: center;

  div {
    background-color: rgba($gray-800, 0.2);
    padding: 20px;

    h3 {
      margin: 15px 0 35px 0;
    }

    a {
      display: block;
      margin: 25px 0;
      text-align: center;
    }

    * {
      color: #fff;
    }
  }
}
