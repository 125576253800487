$breadcrumbs-bg: transparent;
$breadcrumbs-bg-hover: rgba(255, 255, 255, 0.2);
$breadcrumbs-bg-active: rgba(255, 255, 255, 0.3);
$breadcrumbs-border: rgba(255, 255, 255, 0.5);
$breadcrumbs-border-hover: $breadcrumbs-bg-active;
$breadcrumbs-border-active: $breadcrumbs-bg-active;
$breadcrumbs-text-color: #fff;
$breadcrumbs-text-size: 13px;

.breadcrumb {
  padding: 0;
  background-color: transparent;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;

  li {
    color: $breadcrumbs-text-color;
    margin-right: 0;

    .breadcrumb-step {
      display: inline-block;

      > div {
        display: inline-block;
        height: 100%;
        vertical-align: middle;

        text {
          font-size: $breadcrumbs-text-size;
          fill: $breadcrumbs-text-color;
          text-transform: capitalize;
        }

        &:first-child {
          svg {
            margin-left: 0;
          }
        }

        svg {
          height: 25px;

          .background {
            fill: $breadcrumbs-bg;
          }

          .border {
            fill: $breadcrumbs-border;
          }
        }
      }
    }

    &:hover {
      cursor: pointer;

      .breadcrumb-step > div {
        svg {
          .background {
            fill: $breadcrumbs-bg-hover;
          }

          .border {
            fill: $breadcrumbs-border-hover;
          }
        }
      }
    }

    &.active {
      cursor: pointer;

      .breadcrumb-step > div {
        svg {
          .background {
            fill: $breadcrumbs-bg-active;
          }

          .border {
            fill: $breadcrumbs-border-active;
          }
        }
      }
    }
  }

  > li + li:before {
    content: none;
  }
}
