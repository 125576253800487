.modal-loader {
  position: absolute;
  top: 40%;
  padding: 30px 0 25px;
  left: 44.5%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  border-radius: 5px;
  background-color: #fff;
  color: rgb(83, 83, 83);

  z-index: 999;

  h3 {
    font-size: 1.5vw;
  }
}

.payment-error {
  p {
    font-size: 1.6em;
  }
}

.accept-contract-page {
  position: absolute;
  left: 47%;
  top: 13%;
  width: 35%;
  height: 80%;
  padding: 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  color: #fff;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 0px;
  }

  .btn.btn-primary {
    background-color: rgb(0, 196, 201);
    width: 55%;
    height: 8vh;
    font-size: 3vh;
    font-weight: bold;
    color: rgb(255, 255, 255);
    outline: 0;
    margin-top: 4vh;
    border: 0px;
  }
  .buttonDisable {
    background-color: rgba(0, 196, 201, 0.6) !important;
    color: rgba(255, 255, 255, 0.3) !important;
  }
}
