@import 'buy-mold/BuyMold';
@import 'catwalk/Catwalk';
@import 'fabrics/Fabrics';
@import 'models/models';
@import 'user-fabrics/UserFabricsPage';

.catalog {
  &,
  > .row,
  > .row > .col {
    height: 100%;
  }

  .showroom {
    height: 100%;

    .nav-justified > li,
    .nav-tabs.nav-justified > li {
      display: table-cell !important;
      width: 1% !important;

      button {
        @extend a;
        margin-bottom: 0;
        width: 100%;
        background-color: rgba($default-dark-transparency, 0.2);
      }
    }

    .custom-tabs-container {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .tab-content {
      flex: 1;
      overflow-y: hidden;
      display: flex;
      flex-direction: 100%;

      > .tab-pane {
        flex: 1;
        padding: 0 5px;
        opacity: unset !important;
      }
    }

    .nav-tabs {
      padding: 10px 5px;
      padding-bottom: 0;
      border-bottom: 0;

      .nav-item {
        &:last-child button {
          margin-right: 0;
        }

        button {
          color: #ccc;
          background-color: rgba($default-dark-transparency, 0.2);
          border-color: transparent;
          text-transform: uppercase;
          margin-right: 10px;

          &:hover {
            background-color: rgba($default-dark-transparency, 0.3);

            color: #eee;
          }
        }

        button.active,
        button:focus,
        button:hover {
          background-color: $default-dark-transparency;
          border-color: $default-dark-border-color;
          border-bottom-color: transparent;
          color: #fff;
        }
      }
    }
  }
}
