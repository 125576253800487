@font-face {
  font-family: 'Broken';
  src: url('/assets/broken.ttf');
}

.manufacturer-card {
  .manufacturer-logo {
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 10px;

    h4 {
      color: #f00;
      font-family: 'Broken';
      font-size: 3em;
    }
  }

  button {
    background-color: rgb(52, 171, 173);
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 10px;
  }

  .fabric-token-details-text {
    color: #111;
  }
}

.fabric-tokens-by-manufacturer {
  display: block;

  .fabric-token {
    .token {
      position: relative;

      .hoverlay {
        visibility: hidden;
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        background-color: transparent;
        text-align: center;

        a {
          text-decoration: none;
          color: #ddd;

          &:hover {
            color: #fff;
          }

          .fa-heart-o {
            color: rgba(#fff, 0.3);
          }
        }

        .bottom {
          position: absolute;
          bottom: 5px;
          left: 10px;
          right: 10px;
        }

        .middle {
          position: relative;
          height: 100%;
          width: 100%;
          display: table;

          a {
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
    }

    small.caption {
      display: block;
      height: 35px;
      overflow: hidden;
    }

    &:hover {
      cursor: pointer;

      .hoverlay {
        visibility: visible;
      }

      img {
        opacity: 0.7;
      }
    }

    img {
      width: 100%;
      box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.4);
    }
  }
}
