.user-fabrics {
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding: 15px 25px;

  > header {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 30px;
    color: white;
    display: flex;
  }

  > section {
    background-color: rgba(255, 255, 255, 0.16);
    flex: 1;
    overflow-y: auto;
    padding: 10px 15px;
    .fabrics {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      .fabric {
        position: relative;
        padding: 15px 15px;

        .hoverlay {
          visibility: hidden;
          position: absolute;
          top: 5px;
          left: 5px;
          right: 5px;
          bottom: 5px;
          background-color: transparent;
          text-align: center;

          a {
            text-decoration: none;
            color: #ddd;

            &:hover {
              color: #fff;
            }

            .fa-heart-o {
              color: rgba(#fff, 0.3);
            }
          }

          .middle {
            position: relative;
            height: 100%;
            width: 100%;
            display: table;

            a {
              display: table-cell;
              vertical-align: middle;
            }
          }

          .bottom {
            position: absolute;
            bottom: 10px;
            right: 12px;
          }
        }

        &:hover {
          cursor: pointer;

          .hoverlay {
            visibility: visible;
          }

          img {
            opacity: 0.7;
          }
        }

        img {
          width: 100%;
          box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
}

.upload-user-fabric-form {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {

    border-bottom: 1px solid #333;
    -webkit-text-fill-color: #333;

  }
}