.billing-address {
  position: absolute;
  left: 42%;
  top: 15%;
  width: 45%;
  height: 80%;
  padding: 0 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  color: #fff;

  ::-webkit-scrollbar {
    width: 0px;
  }

  .text-address {
    font-size: 2.5vh;
  }
  #text-select-address {
    margin-top: 7vh;
    font-size: 3.5vh;
  }

  .registered-addresses {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 2.5vh;
    margin-left: -1.5vw;
    width: 30vw;
    max-height: 370px;
    overflow: auto;

    :after {
      content: '';
      clear: both;
    }

    .address {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.4) !important;
      padding-top: 1vh;
      margin-bottom: 2vh;
      margin-left: 5%;
      width: 27vw;
      cursor: pointer;

      .name {
        margin-bottom: 0%;
        font-size: 1vw;
        font-weight: 600;
      }

      #hr-svg {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: -1vh;
        margin-left: -9%;
        z-index: 10;
        #hr {
          height: 0.1vh;
          width: 24.5vw;
          background-color: #fff;
        }
      }

      .address-address {
        width: 80%;
        margin-top: -3%;
        margin-bottom: 0.5vh;
        font-size: 2.2vh;
        font-weight: 500;
      }

      .icons {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 1vh;
        div + div {
          margin-left: 100%;
        }
      }
    }
  }

  .add-address {
    margin-top: 2.5vh;
    font-size: 2vh;
    font-weight: 500;
    cursor: pointer;
    a {
      color: #fff;
    }
  }

  .btn.btn-primary {
    background-color: rgb(0, 196, 201);
    width: 45%;
    height: 8vh;
    font-size: 3vh;
    font-weight: bold;
    color: rgb(255, 255, 255);
    outline: 0;
    margin-top: 4vh;
    border: 0px;
  }
  .buttonDisable {
    background-color: rgba(0, 196, 201, 0.6) !important;
    color: rgba(255, 255, 255, 0.3) !important;
  }
}
