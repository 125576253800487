@media print {
  html {
    height: auto;
  }
  .full-height {
    height: auto;
  }

  * {
    font-size: 12px;
  }
}

@page {
  size: landscape;
  marks: none;
  bleed: none;
}

.print-model-sheet-page {
  color: black;
  display: block;
  padding-top: 20px;

  @media print {
    padding: 0;
  }

  .navbar-default {
    background-color: #323639;
    border-color: #323639;
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.3);

    a {
      color: #fff !important;
      .mc-icon-svg {
        width: 22px !important;
        height: 22px !important;
      }
    }

    .btn-primary {
      background-color: #01a2a6;
      border-color: #01a2a6;

      &:hover {
        background-color: #008a8d;
        border-color: #008a8d;
      }
    }
  }

  .flex {
    display: flex;
    flex-direction: row;
  }

  .flex-row {
    flex-direction: column;
  }

  p {
    margin-bottom: 5px;
  }

  hr {
    margin: 10px 0;
  }

  .model {
    flex: 1;
    padding-right: 10px;
    border-right: 1px solid black;

    .model-reference {
      margin: 0;
      background-color: #ddd;
      border: 2px solid #888;
      text-align: center;
    }

    .model-info {
      flex-basis: 35%;

      .modacad-logo {
        text-align: center;
      }

      img {
        width: 80%;
      }
    }

    .model-preview {
      flex-basis: 60%;

      .render-model {
        padding: 10px 20px 0 50px;
      }
    }
  }

  .fabric {
    flex: 1;
    padding-left: 10px;
    position: relative;

    .fabric-token {
      box-shadow: 1px 3px 10px 2px rgba(0, 0, 0, 0.15);
      display: flex;
      flex-direction: column;
      height: 56%;

      .token-caption {
        padding: 5px 10px;
        font-weight: bold;
      }

      .token-image {
        flex: 1;
        overflow: hidden;
      }

      img {
        width: 100%;
      }
    }

    .fabric-info {
      padding-top: 15px;
      .flex {
        div {
          flex: 1;
        }
      }
    }

    .manufacturer {
      text-align: center;

      .manufacturer-logo {
        background-color: #ddd;
        border: 2px solid #888;
        color: #333;
        border-radius: 0px;
        display: inline-block;
        padding: 0 10px;

        h4 {
          color: #000;
          font-family: 'Broken';
          font-size: 2em;
        }
      }
    }
  }

  .box {
    border: 1px solid black;
    margin: 10px 0;
    padding: 5px 10px;

    &.disabled {
      border-color: rgba(0, 0, 0, 0.5);
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
