.buy-mold-sizes {
  .app-buy-mold-content {
    .onoffswitch {
      margin: 0 10px;
    }

    form {
      padding-top: 10px;
      padding-right: 20px;

      h3 {
        margin-top: 25px;
      }
    }

    .btn-custom {
      margin-left: 20px;
      font-size: 0.8em;
    }
  }

  .table {
    color: rgba(black, 0.5);

    .strong,
    > tbody > tr.selected > td {
      font-weight: bold;
      color: rgba(black, 0.6);
    }

    tbody {
      tr {
        td:nth-child(2) {
          white-space: nowrap;
        }
      }
    }

    .price {
      margin-right: 20px;
      white-space: nowrap;
    }

    > thead > tr > th {
      border-bottom: 1px solid rgba(#ddd, 0.1);
      font-weight: lighter;
    }

    > thead > tr > th,
    > thead > tr > td,
    > tbody > tr > th,
    > tbody > tr > td,
    > tfoot > tr > th,
    > tfoot > tr > td {
      border-top: 1px solid rgba(#ddd, 0.1);
    }
  }

  h3 {
    color: #333;
    white-space: nowrap;
  }
}
