@import 'LoginPage';

.auth-pages,
.payment-pages {
  #div-main {
    color: #fff !important;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .arrow {
    opacity: 80%;
    left: -13%;
    top: 22% !important;
  }

  input:focus {
    border-top: 0px;
    outline: 0;
    outline-style: 0;
  }

  div.curve {
    display: flex;
    justify-content: center;
    position: absolute;
    max-width: 550px;
    background-color: #fff;
    width: 27.5vw;
    height: 120%;
    top: -10%;
  }

  div.curve-top {
    border-bottom-right-radius: 60%;
    border-top-right-radius: 60%;
  }

  #logo-modacad {
    width: 75%;
  }

  #icon-modacad {
    position: absolute;
    width: 9%;
    height: 7%;
    top: 20%;
    margin-left: 60%;
  }
}
