.add-address-form {
  position: absolute;
  left: 42%;
  top: 15%;
  width: 45%;
  height: 80%;
  padding: 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  color: #fff;

  h2 {
    margin-bottom: 5vh;
  }

  .address-data {
    font-size: 3vh;
  }

  .inputs-form {
    margin-top: 5vh;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    + .inputs-form {
      margin-top: 2vh;
    }
  }

  .form-control {
    color: #ffffff;
    height: 5vh;
    font-size: 1.2rem;
    font-weight: lighter;
    background-color: transparent !important;
    border: 0px;
    border-bottom: 1px solid #fff;
    text-align: center;
    padding: 0 0 0 0;
    box-shadow: unset;
    text-align: left;
    margin-bottom: 2vh;
  }

  .btn.btn-primary {
    background-color: rgb(0, 196, 201);
    width: 60%;
    height: 8vh;
    font-size: 3.5vh;
    font-weight: bold;
    color: rgb(255, 255, 255);
    outline: 0;
    margin-top: 5vh;
    border: 0px;
  }
  .buttonDisable {
    background-color: rgba(0, 196, 201, 0.6) !important;
    color: rgba(255, 255, 255, 0.3) !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: black !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}
