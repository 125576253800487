$shadow-color: rgba(50, 50, 50, 0.35);
$base-font: 18px;
$color: rgb(100, 100, 100);
$mc-primary: #07b4b6;

@import 'BuyMoldMeasures';
@import 'BuyMoldSuccess';
@import 'PreviewSelectedModel';
@import 'BuyMoldSelectedSize';
@import 'BuyMoldSizes';

.buy-mold {
  display: block;

  .row {
    display: flex;
    flex-direction: row;
  }

  .app-buy-mold-preview {
    flex: 1;
    display: flex;
  }

  .buy-mold-sizes,
  .buy-mold-measures,
  .help-with-measurement,
  .buy-mold-success {
    display: block;

    .mc-icon,
    .mc-icon.mc-icon-inverse {
      svg * {
        fill: $color;
      }
    }

    .app-buy-mold-content {
      padding: 15px 30px 15px 15px;

      h4 {
        margin-top: 35px;
        color: $color;
      }

      .form-group * {
        color: $color;
        font-size: 20px;
      }

      .required {
        color: red;
      }

      hr + .text-center {
        padding: 15px;

        a {
          text-decoration: none;
          color: $color;
        }
      }
    }

    .btn-custom {
      background-color: $mc-primary;
      background-image: none;
      font-size: 1em;
      padding: 6px 20px;
    }
  }
}
