.accept-contract {
  @include media-breakpoint-up(lg) {
    max-height: 70%;
  }

  .content-contract {
    color: #fff;
    background-color: rgba($black, 0.3);
    padding: 0 15px;
    margin: 15px 7vw;
    max-height: 50vh;
    overflow-y: auto;

    h3,
    h4 {
      color: #fff;
      font-size: 14px;
    }

    p,
    li {
      font-size: 12px;
      text-align: justify;
    }

    @include media-breakpoint-up(lg) {
      padding: 25px 25px 25px 25px;
      max-height: 80%;
      background-color: rgba(0, 0, 0, 0.4);
      font-size: 1.7vh;
      margin-top: 7%;
      overflow: auto;
      p {
        max-height: 100%;
      }
    }
  }

  #terms-and-conditions--header,
  #privacy-policy--header {
    h1 {
      color: #fff;
      font-size: 1.5rem;
    }
  }


  #terms-and-conditions--body,
  #privacy-policy--body {
    margin-left: -15px;
    margin-right: -15px;
    .container {
      width: 100%;

      .col-md-3 {
        display: none;
      }

      .col-md-9 {
        @extend .col;

        p:first-child {
          display: none;
        }
      }
    }
  }

  #terms-and-conditions--body {
    h4 {
      display: none;
    }
  }

  .accept-form {
    margin-top: 20px;

    @include media-breakpoint-up(lg) {
      font-size: 1.7vh;
    }

    label {
      padding-top: 6px;
      padding-left: 10px;
    }

    .custom-control-label::before,
    .custom-control-label::after {
      top: .4rem;
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}