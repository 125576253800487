.actions-toolbar {
  background-color: $default-dark-transparency;
  display: block;
  position: absolute;
  top: 10px;
  left: 0;
  width: $selected-model-toolbar-width;
  z-index: 1;
  box-shadow: 0 5px 20px 0 rgba($default-dark-transparency, 0.5);
  border: 1px solid $default-dark-border-color;

  ul.nav.flex-column {
    margin: 8px;

    li {
      span {
        display: none;
        padding-left: 10px;
        color: #000;
      }

      a > div {
        display: inline-block;
      }

      a,
      label {
        display: inline-block;
        padding: 10px;
        border-bottom: none;
        font-weight: normal;
        img,
        .mc-icon-svg {
          width: 22px;
          height: 22px;
        }
      }

      label {
        background-color: rgba(255, 255, 255, 0.3);
      }

      .input-upload-tecido {
        display: none;
      }

      &.disabled {
        &,
        &:hover {
          width: auto;

          a,
          label {
            border-bottom: none;
            background-color: rgba(#fff, 0.05);
            .mc-icon * {
              fill: rgba(#fff, 0.1);
            }
          }

          span {
            display: none;
          }
        }
      }

      &.active {
        a,
        label {
          &.add-to-cart {
            background-color: rgba(52, 171, 173, 0.85);
          }
        }
      }

      &.active,
      &:hover {
        width: 300px;

        a,
        label {
          width: auto;
          background-color: rgba($default-light-transparency, 0.5);
          cursor: pointer;
        }

        span {
          display: inline;
        }
      }
    }
  }
}

form {
  .box-imagem {
    width: 100%;
    margin-bottom: 15px;
  }
}
