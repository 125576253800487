.shopping-cart-header {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 30px;
  color: white;
  display: flex;
  flex-shrink: 0;

  .mc-icon {
    cursor: pointer;
  }

  .mc-icon-svg {
    * {
      fill: white;
    }
  }

  > span:nth-child(1) {
    flex: 1;
    font-weight: bold;
    line-height: 3em;

    a {
      color: white;
      opacity: 0.9;
      text-decoration: none;

      &:hover {
        opacity: 1;
      }
    }

    .mc-icon-svg {
      vertical-align: text-bottom;
    }
  }

  > span:nth-child(2) {
    flex: 1;
    text-align: right;
    line-height: 1.33333;
    font-size: 18px;
    line-height: 2em;

    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .mc-icon-svg {
        vertical-align: text-bottom;
      }

      input {
        display: inline-block;
        width: 100px;
        margin: 0 10px;
      }

      input[type='range'] {
        -webkit-appearance: none;
      }
      input[type='range']:focus {
        outline: none;
      }
      input[type='range']::-webkit-slider-runnable-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        background: #fff;
        border-radius: 1px;
        border: 0px solid #000000;
      }
      input[type='range']:focus::-webkit-slider-runnable-track {
        background: #fff;
      }
      input[type='range']::-moz-range-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        background: #fff;
        border-radius: 1px;
        border: 0px solid #000000;
      }
      input[type='range']::-ms-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        color: transparent;
      }

      input[type='range']::-webkit-slider-thumb {
        box-shadow: 0px 0px 0px #000000;
        border: 3px solid #ffffff;
        height: 13px;
        width: 13px;
        border-radius: 13px;
        background: #70503e;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -5px;
      }
      input[type='range']::-moz-range-thumb {
        box-shadow: 0px 0px 0px #000000;
        border: 3px solid #ffffff;
        height: 13px;
        width: 13px;
        border-radius: 13px;
        background: #70503e;
        cursor: pointer;
      }
      input[type='range']::-ms-thumb {
        box-shadow: 0px 0px 0px #000000;
        border: 3px solid #ffffff;
        height: 13px;
        width: 13px;
        border-radius: 15px;
        background: #70503e;
        cursor: pointer;
      }

      input[type='range']::-ms-fill-lower {
        background: #fff;
        border: 0px solid #000000;
        border-radius: 2px;
        box-shadow: 0px 0px 0px #000000;
      }
      input[type='range']::-ms-fill-upper {
        background: #fff;
        border: 0px solid #000000;
        border-radius: 2px;
        box-shadow: 0px 0px 0px #000000;
      }
      input[type='range']:focus::-ms-fill-lower {
        background: #fff;
      }
      input[type='range']:focus::-ms-fill-upper {
        background: #fff;
      }
    }
  }

  > span:nth-child(3) {
    flex: 2;
    text-align: right;
    font-weight: bold;
    font-size: 20px;

    .btn-custom {
      background: $mc-primary;
      text-transform: uppercase;
      margin-left: 30px;
      font-weight: bold;
      padding: 10px 30px;
    }
  }
}
