$mc-primary: #01a2a6;

.order-page {
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
  @extend .full-height;

  > header {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 15px 30px;
    color: white;

    font-weight: bold;

    h3 {
      margin-top: 10px;
      display: flex;
      div:first-child {
        flex: 1;
      }
    }

    .mc-icon svg {
      vertical-align: middle;
      margin-right: 15px;
      height: 22px;
      width: 22px;
    }
  }

  > section {
    flex: 1;
    background-color: rgba(255, 255, 255, 0.16);
    display: flex;
    overflow: auto;
    flex-direction: column;

    .payment {
      margin: auto;
      color: #fff;
      padding: 15px;
    }

    .orders {
      border-top: 1px dashed rgba(255, 255, 255, 0.3);
      padding: 10px;
      display: flex;
      flex-direction: column;
      flex: 1;

      .order {
        color: #fff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        .order-inner {
          display: flex;

          header {
            flex: 1;
          }

          section {
            display: flex;
            flex: 6;

            .preview {
              height: 50px;
              flex: 1;
            }

            .description {
              display: flex;
              flex: 8;

              > * {
                flex: 1;
              }

              .model-size {
                order: -1;
              }

              .sizes {
                align-items: flex-start;
                padding-right: 30px;
                display: flex;
                flex-wrap: wrap;

                span {
                  display: flex;
                  flex-basis: 25%;
                }
              }

              .price {
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                font-size: 1.2em;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}
