$fabrics-padding: 10px 30px;

@import 'FabricsBySubgroup';
@import 'FabricTokens';

.fabrics-catalog {
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;

  color: #ddd;

  .header {
    min-height: 94px;
    padding: $fabrics-padding;

    background-color: $default-dark-transparency;
    border: 1px solid $default-dark-border-color;
    border-top: transparent;
    border-bottom: transparent;

    form > .search {
      position: relative;

      &.search-filtered-by-model {
        .selected-model {
          display: initial;
        }
      }

      .selected-model {
        display: none;
        position: absolute;
        bottom: 6px;
        left: 30px;
        background-color: $default-light-transparency;
        padding: 3px 8px;

        .mc-icon-svg {
          vertical-align: middle;
        }
      }

      > .mc-icon {
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0.3;
      }
    }

    .form-control {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid rgba($default-light-transparency, 0.3);
      margin-bottom: 10px;
      box-shadow: none;
      color: #fff;
      padding-left: 25px;
    }
  }

  .search-results {
    background-color: $default-dark-transparency;
  }

  .fabric-options {
    position: absolute;
    top: 94px;
    bottom: 0;
    width: 100%;

    > div.vertical-scroll {
      height: 100%;
      position: relative;
    }

    .fabrics-subgroup-by-group,
    .fabric-manufacturers {
      background-color: rgba($default-light-transparency, 0.3);
    }

    &.dark {
      .fabrics-subgroup-by-group,
      .fabric-manufacturers {
        background-color: rgba($default-dark-transparency, 0.6);
      }
    }
  }

  h4 {
    margin-top: 0;
    text-transform: uppercase;
    font-size: 1rem;
  }
}

.fabrics-light-mode {
  a {
    text-decoration: none;
  }

  * {
    color: #fff;
  }
}

.fabric-groups,
.fabrics-subgroup-by-group,
.fabric-manufacturers {
  display: block;
  height: 100%;
  background-color: $default-dark-transparency;
  padding: $fabrics-padding;
  border: 1px solid $default-dark-border-color;
  box-shadow: inset 0px 5px 40px 0px rgba($default-dark-transparency, 0.3);
  box-shadow: 0px 1px 1px 0px $default-dark-transparency;
}

.fabrics-by-search {
  display: block;
  padding: 0 30px 30px 30px;
}
