@import 'desktop-theme';
@import '~perfect-scrollbar/css/perfect-scrollbar';
@import '~bootstrap/scss/bootstrap';
@import 'ui/ui';

@import 'shared/shared';
@import 'auth/auth';
@import 'address/EditAddressPage';
@import 'catalog/catalog';
@import 'layouts/layouts';
@import 'orders/orders';
@import 'payment/CartPaymentPage';
@import 'shopping-cart/ShoppingCartPage';
@import 'print/PrintModelSheetPage';
@import 'forms';

html {
  height: 100%;
}

body {
  height: 100%;
  background-image: linear-gradient(#ff7154, #eb5d40, #c0172c);
  background-size: 100% 100%;
  background-position: center center;
  color: #fff !important;
}

.full-height {
  height: 100%;
  overflow: hidden;
}

$color_robins_egg_blue_approx: #08cccf;
$color_persian_green_approx: #0b9c9e;

.btn-custom {
  color: #ffffff;
  background: $color_robins_egg_blue_approx;
  background-image: linear-gradient(to bottom, $color_robins_egg_blue_approx, $color_persian_green_approx);
  border-color: darken($color_robins_egg_blue_approx, 5%);
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.open .dropdown-toggle.btn-custom {
  color: #ffffff;
  background-color: darken($color_robins_egg_blue_approx, 5%);
  border-color: darken($color_robins_egg_blue_approx, 5%);
}

.btn-custom:active,
.btn-custom.active,
.open .dropdown-toggle.btn-custom {
  background-image: none;
}

.btn-custom.disabled,
.btn-custom[disabled],
fieldset[disabled] .btn-custom,
.btn-custom.disabled:hover,
.btn-custom[disabled]:hover,
fieldset[disabled] .btn-custom:hover,
.btn-custom.disabled:focus,
.btn-custom[disabled]:focus,
fieldset[disabled] .btn-custom:focus,
.btn-custom.disabled:active,
.btn-custom[disabled]:active,
fieldset[disabled] .btn-custom:active,
.btn-custom.disabled.active,
.btn-custom[disabled].active,
fieldset[disabled] .btn-custom.active {
  background: $color_robins_egg_blue_approx;
  background-image: linear-gradient(to bottom, $color_robins_egg_blue_approx, $color_persian_green_approx);
  border-color: #130269;
}

.btn-custom .badge {
  color: $color_robins_egg_blue_approx;
  background-color: #ffffff;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #fff !important;
  opacity: 0.6;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff !important;
  opacity: 0.6;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff !important;
  opacity: 0.6;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff !important;
  opacity: 0.6;
}

.nav.nav-pills.flex-column {
  margin-bottom: 15px;

  li {
    margin: 0;

    > a {
      background-color: $default-light-transparency;
      color: #ddd;
      border-bottom: 1px solid $default-dark-border-color;

      &:hover {
        background-color: rgba($default-light-transparency, 0.5);
        color: #fff;
      }
    }

    &:last-child {
      a {
        border-bottom: none;
      }

      &.disabled {
        a {
          border-bottom: none;
        }
      }
    }

    &.active {
      a,
      a:focus {
        background-color: rgba($default-light-transparency, 0.5);
        color: #fff;
      }
    }

    &.disabled {
      > a {
        &,
        &:focus,
        &:hover {
          background-color: rgba($default-light-transparency, 0.1);
          color: rgba($default-light-transparency, 0.25);
          border-bottom: 1px solid rgba($default-dark-border-color, 0.1);
        }
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  font-family: inherit;
}

