.fabrics-by-subgroup {
  display: block;
  height: 100%;
  background-color: $default-dark-transparency;
  padding: $fabrics-padding;
  border: 1px solid $default-dark-border-color;
  box-shadow: inset 0px 5px 40px 0px rgba($default-dark-transparency, 0.3);
  box-shadow: 0px 1px 1px 0px $default-dark-transparency;

  h5 {
    text-transform: uppercase;

    .fabrics-light-mode {
      margin-top: -5px;
    }
  }

  &.light {
    background-color: darken($default-light-transparency, 25%);
  }

  .fabric {
    a {
      text-decoration: none;
      color: #eee;
      display: block;
    }

    div {
      height: 50px;
    }

    img {
      width: 100%;
    }

    small {
      display: block;
      max-height: 30px;
      overflow: hidden;
    }
  }
}
