@import 'model-variations/SelectedModelVariations';
@import 'SelectedFabric';

.mold-toolbar {
  position: absolute;
  top: 10px;
  right: 0;
  bottom: 10px;

  width: $selected-model-variations;

  overflow-y: hidden;
  z-index: 1;

  > div:first-child {
    background-color: $default-dark-transparency;
    overflow-y: hidden;
    padding: 10px;
    border: 1px solid $default-dark-border-color;
    border-bottom-color: transparent;
    margin-bottom: 20px;

    button {
      margin-top: 20px;
    }
  }
}
