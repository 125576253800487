#buttonLogin {
  position: relative;
  top: 8px;
  margin-left: 5%;
  border-radius: 20px;
  width: 8%;
  height: 35px;
  border: 0px;
  font-weight: bold;
  color: #fff;
  font-size: 2vh;
  background-color: #00c4c9;
}

.full-layout {
  background-image: url('/assets/bg/violate.jpg');
  background-size: cover;
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;

  .main-container {
    @extend .container-fluid;
    padding-top: 50px;
    padding-bottom: 10px;
  }
}

.print {
  margin: 0;
  background-color: #888;
  padding: 50px 0 200px 0;
  width: 100%;

  &.landscape {
    .page {
      width: 29cm;
      height: 20.5cm;
      background-color: #fff;
      margin: 0 auto;
      padding: 20px;
    }
  }
}

@media print {
  .print {
    padding: 0;
    font-size: 0.65em;

    .page {
      page-break-after: always;
    }
  }
}
