.buy-mold-success {
  .app-buy-mold-content {
    > div {
      padding: 60px 100px;
    }

    h3 {
      text-align: center;
      margin: 60px 0;
      color: $color;
    }

    a {
      font-size: 18px;
      display: block;
      text-decoration: none;
      color: $color;
      padding-bottom: 15px;

      .mc-icon {
        vertical-align: text-top;
      }
    }
  }
}
