$mc-primary: #01a2a6;

@import 'ShoppingCartHeader';

.shopping-cart-page {
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
  @extend .full-height;

  > section {
    flex: 1;
    background-color: rgba(255, 255, 255, 0.16);
    display: flex;
    overflow: auto;

    .orders {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .order {
      .order-inner {
        width: 100%;
      }

      section {
        display: flex;

        .preview {
          display: flex;
          flex: 1;
          position: relative;

          .render-model {
            svg:nth-child(2) {
              position: absolute;
            }
          }
        }

        .description {
          font-size: 0.9em;
          padding: 15px 0 15px 15px;
          flex: 1;

          .sizes {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
          }

          .price {
            font-size: 1.3em;
            font-weight: bold;
          }
        }
      }
    }
  }

  &.list {
    > section {
      .orders {
        flex-direction: row;
        align-items: flex-start;
        align-content: flex-start;
      }

      .order {
        flex-basis: 100%;
        border-bottom: 1px solid rgba(#fff, 0.2);

        &:nth-child(even) {
          background-color: rgba(#fff, 0.1);
        }

        .order-inner {
          display: flex;
          flex-direction: row;
          color: white;

          > header {
            flex: 1;
            padding: 15px 0 15px 15px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;

            a {
              display: none;
            }
          }

          > section {
            flex: 6;

            .preview {
              height: 60px;
              margin: 10px;
              padding: 10px 0;
              flex: 1;
              background-color: rgba(255, 255, 255, 0.4);

              svg:first-child {
                [id^='Boneca'],
                [id^='Costas'] {
                  visibility: hidden;
                }
              }
            }

            .description {
              display: flex;
              flex-direction: row;
              background-color: transparent;
              flex: 15;

              > div {
                flex: 1;
              }

              .model-size {
                order: -1;
              }

              .sizes {
                flex: 2;
                justify-content: initial;
                align-items: flex-start;
                padding-right: 30px;

                span {
                  display: flex;
                  flex-basis: 20%;
                }
              }

              .actions {
                a {
                  display: block;
                  text-decoration: none;
                  color: white;
                  opacity: 0.9;
                  margin-bottom: 10px;

                  &:hover {
                    opacity: 1;
                  }

                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }

              .price {
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  &.grid {
    &.grid-12 {
      > section {
        .order {
          flex-basis: 33.333%;

          @include media-breakpoint-up(sm) {
            flex-basis: 20%;
          }
          @include media-breakpoint-up(md) {
            flex-basis: 14.28%;
          }
          @include media-breakpoint-up(lg) {
            flex-basis: 12.5%;
          }
          @include media-breakpoint-up(xl) {
            flex-basis: 10%;
          }

          header {
            display: none;
          }

          section {
            position: relative;

            .preview {
              padding: 20px 15px;
            }

            .description {
              display: block;
              position: absolute;
              bottom: 0;
              right: 0;
              padding: 0;

              > * {
                display: none;
              }

              .actions {
                display: block;
                position: absolute;
                bottom: 10px;
                right: 10px;

                > a {
                  display: none;

                  &:last-child {
                    display: inline-block;
                    background-color: #fff;
                    padding: 10px;
                    border-radius: 25px;

                    .mc-icon-svg {
                      width: 20px;
                      height: 20px;
                      * {
                        fill: #01a2a6;
                      }
                    }

                    span:last-child {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.grid-6 {
      .order {
        flex-basis: 50%;

        @include media-breakpoint-up(sm) {
          flex-basis: 25%;
        }

        @include media-breakpoint-up(md) {
          flex-basis: 20%;
        }

        @include media-breakpoint-up(lg) {
          flex-basis: 16.66666%;
        }

        @include media-breakpoint-up(xl) {
          flex-basis: 12.5%;
        }

        header {
          font-size: 0.85em;

          a {
            display: none;
          }
        }

        section {
          position: relative;

          .preview {
            padding: 25px 30px;

            .render-model {
              g[id^='Costas'] {
                visibility: hidden;
              }
            }
          }

          .description {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            left: 50%;
            background-color: transparent;
            font-size: 0.8em;
            padding: 15px 0;

            .measures {
              display: none;
            }

            .sizes {
              align-self: flex-end;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: initial;
              margin: 10px 0 0 0;

              span {
                display: block;
                width: 100%;
                background-color: #fff;
                border: none;
                padding: 2px 10px;
                box-shadow: 0px 0px 20px 0px rgba(#000, 0.5);
              }
            }

            .model-size {
              text-align: right;
              padding: 5px 10px;
              background-color: #fff;
              box-shadow: 0px 0px 20px 0px rgba(#000, 0.3);
            }

            .actions {
              display: block;
              position: absolute;
              bottom: 10px;
              left: -90%;

              > a {
                display: none;

                &:last-child {
                  display: inline-block;
                  background-color: #fff;
                  padding: 5px;
                  border-radius: 25px;

                  .mc-icon-svg {
                    width: 15px;
                    height: 15px;
                    * {
                      fill: #01a2a6;
                    }
                  }

                  span:last-child {
                    display: none;
                  }
                }
              }
            }

            .price {
              position: absolute;
              bottom: 0;
              right: 0;
            }
          }
        }
      }
    }

    &.grid-4 {
      .order {
        flex-basis: 100%;

        @include media-breakpoint-up(sm) {
          flex-basis: 50%;
        }

        @include media-breakpoint-up(md) {
          flex-basis: 33.3333%;
        }

        @include media-breakpoint-up(lg) {
          flex-basis: 25%;
        }

        @include media-breakpoint-up(xl) {
          flex-basis: 20%;
        }

        header {
          padding: 5px 10px;
        }

        .actions {
          > * {
            display: block;
          }
        }
      }
    }

    > section {
      padding: 10px 15px;
      align-content: space-between;
      align-items: flex-start;

      .order {
        display: flex;
        padding: 15px;
        flex-grow: 0;

        .order-inner {
          box-shadow: 2px 2px 20px 1px rgba(black, 0.3);
          display: flex;
          flex-direction: column;
        }

        header {
          background-color: $mc-primary;
          color: white;
          display: flex;
          padding: 5px 0;

          .mc-icon-svg {
            width: 15px;
            height: 15px;
          }

          span {
            flex: 1;
            text-align: center;
            font-weight: bold;
          }
        }

        section {
          flex: 1;

          .preview {
            background-color: rgba(255, 255, 255, 0.4);
            box-shadow: inset -2px 2px 30px 0 rgba(0, 0, 0, 0.4);
            padding: 30px 15px;
            flex-basis: 50%;

            .render-model {
              display: flex;
              align-items: center;
            }
          }

          .description {
            color: darken(#919191, 15%);
            background-color: #fff;
            flex-basis: 50%;
            display: flex;
            flex-direction: column;

            .sizes {
              margin: 30px 15px 20px 0;
              flex: 1;
              align-items: flex-start;
              align-content: flex-start;

              span {
                border: 1px solid darken(#919191, 10%);
                width: 45%;
                text-align: center;
                margin-bottom: 10px;
              }
            }

            .actions {
              .remove-item {
                display: none;
              }

              a {
                text-decoration: none;
                color: inherit;

                .mc-icon-svg * {
                  fill: darken(#919191, 15%);
                }

                &:hover {
                  color: darken(#919191, 30%);

                  .mc-icon-svg * {
                    fill: darken(#919191, 30%);
                  }
                }
              }
            }

            .price {
              background-color: $mc-primary;
              color: white;
              padding: 15px;
              text-align: right;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
